.alert-view__overlay {
  position: fixed;
  display: table;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, .5);
  opacity: 0.5;
  transition: opacity .2s ease-out;
  &.alert-view__overlay--display {
    opacity: 1;
  }
}
.alert-view__dialog-wrap {
  display: table-cell;
  vertical-align: middle;
}
.alert-view__dialog {
  transform: scale(.9, .9);
  transition: transform .2s ease-out;
  width: 270px;
  margin: 0 auto;
  max-width: 270px;
  border-radius: 13px;
  background-color: rgba(248, 248, 248, 0.95);
}
.alert-view__overlay--display .alert-view__dialog {
  transform: scale(1, 1);
}
.safari .alert-view__dialog {
  background-color: rgba(248, 248, 248, 0.8);
  -webkit-backdrop-filter: blur(10.8px);
  backdrop-filter: blur(10.8px);
}
.alert-view__title {
  text-align: center;
  padding: 19px 15px 0;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: -0.4px;
}
.alert-view__msg {
  text-align: center;
  font-size: 13px;
  line-height: 1.23;
  padding: 6px 15px 21px;
}
.alert-view__buttons {
  li {
    border-top: solid 1px #ceced2;
    cursor: pointer;
    button {
      width: 100%;
      padding: 10px 0;
      text-align: center;
      font-size: 17px;
      font-weight: 300;
      color: #0076ff;
      &.strong {
        font-weight: 600;
      }
    }
  }
}
