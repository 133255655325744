/*


 */

@import "configurations";

// 개편 스타일 작업 시작
// 로그인, 가입 base common
.box2-groups {
  overflow: hidden;
  margin-bottom: 10px;
  .ra-input {
    overflow: hidden;
    position: relative;
    margin: 0;
    border: 0 none;
    border-radius: 0;
    border-top: 1px solid $Line6-Color;
    .text-error, .help-text {
      position: absolute;
      top: 17px;
      right: 12px;
      padding: 0;
      font-size: 14px;
      line-height: 16px;
    }
  }
  > .ra-input:first-child {
    border-top-color: transparent;
  }
}


fieldset {
  position: relative;
  text-align: left;

  &.fieldset-group {
    margin-bottom: 10px;
  }
  &.field-input {
    z-index: 0;

    label {
      position: absolute;
      top: 19px;
      left: 20px;
      font-size: 14px;
      line-height: 14px;
      color: #333333;
      @include mobile() {
        font-size: 13px;
        top: 15px;
        left: 13px;
      }
    }

    input[type=text], input[type=email], input[type=password] {
      transition: border-color .25s ease, box-shadow .25s ease;
      width: 100%;
      box-sizing: border-box;
      border: solid 1px #e2e2e2;
      font-size: 16px;
      line-height: 16px;
      padding: 42px 20px 18px 20px;
      font-family: "Helvetica Neue", Helvetica, sans-serif;
      color: #333333;
      ime-mode: disabled;

      &[readonly], &:read-only {
        color: #4778d9;
      }
      @include mobile() {
        font-size: 13px;
        line-height: 13px;
        padding: 35px 13px 11px 13px;
      }
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #bbbbbb;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: #bbbbbb;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: #bbbbbb;
    }
  }
  &.field-disabled {
    label {
      color: #888888;
    }
    input[type=text], input[type=email], input[type=password] {
      background-color: #f4f4f4;
      color: #888888;
    }
    input[type=text]:focus, input[type=email]:focus, input[type=password]:focus {
      border: solid 1px #e2e2e2;
      box-shadow: none;
    }
  }
  &.field-input--focus {
    z-index: 1;
  }
}

.guide-message {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #555459;
}

/* ----------------------------------------------------------------------------
 * `/login` Page
 */

.ag-login {
  max-width: 500px;
  padding-top: 98px;
  padding-bottom: 100px;
  box-sizing: border-box;
  margin: 0 auto;
  text-align: center;
  @include mobile() { padding: 35px 10px 0; }

  .invitation-logo {
    display: inline-block;
    width: 174px;
    height: 146px;
    margin-bottom: 6px;
  }

  .ag__title {
    font-size: 36px;
    line-height: 38px;
    font-weight: 600;
    color: #222222;
    margin-bottom: 5px;
    @include mobile() {
      font-size: 20px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }

  .ag__subtitle {
    font-family: "Helvetica Neue", Helvetica, sans-serif;
    line-height: 21px;
    font-size: 16px;
    font-weight: normal;
    color: #555459;
    margin-bottom: 33px;
    @include mobile() { font-size: 13px; margin-bottom: 28px; }
  }

  #planetSubdomainForm {
    .field-subdomain {
      box-sizing: border-box;
      width: 100%;
      background-color: #ffffff;
      border: solid 1px #e2e2e2;
      margin-bottom: 10px;
      position: relative;
      font-size: 26px;
      line-height: 30px;

      input[type=text] {
        width: 100%;
        box-sizing: border-box;
        padding: 15px 92px 15px 15px;
        border: 0 none;
        font-size: 26px;
        line-height: 30px;
        text-align: right;
        color: #555459;
      }

      .domain-postfix {
        position: absolute;
        top: 15px;
        right: 12px;
        width: 79px;
        color: #aaa9ac;
      }
    }
  }

  .error-message {
    padding: 30px 0 0;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #e94a35;
  }

  .planet-lost-guide {
    padding: 30px 0 70px;
    a {
      height: 19px;
      font-size: 16px;
      color: #888888;
      text-decoration: underline;
    }
  }

  .visited-planets {
    li {
      position: relative;
      height: 70px;
      box-sizing: border-box;
      background-color: #f9f9f9;
      border: solid 1px #e2e2e2;
      padding: 18px 15px 19px 20px;
      margin-bottom: 10px;

      .planet-name {
        text-align: left;
        line-height: 19px;
        font-size: 15px;
        font-weight: bold;
        color: #2c2d30;
      }
      .planet-domain {
        text-align: left;
        line-height: 14px;
        font-size: 12px;
        color: #9e9e9e;
      }
      a.login-button {
        border-radius: 3px;
        background-color: #ffffff;
        border: solid 1px #e0e0e0;
        line-height: 16px;
        font-size: 13px;
        padding: 8px 17px;
        font-weight: 600;
        color: #5e5e5e;
        position: absolute;
        top: 17px;
        right: 15px;
        text-decoration: none;
      }
    }
  }

  .login-help {
    margin-bottom: 20px;
  }

  .join-help {
    margin-top: 50px;
  }
  #loginForm {
    width: 100%;
    margin-bottom: 60px;
    @include mobile() { margin-bottom: 25px; }

    fieldset {
      position: relative;
      text-align: left;

      &.field-password {
        top: -1px;
        label {
          color: #888888;
        }
      }
      &.field-password-confirmation {
        top: -2px;
        input[type=password] {
          padding-top: 20px;
          @include mobile() {
            padding-top: 15px;
            padding-bottom: 15px;
          }
        }
      }

      &.field-autologin {
        padding-top: 7px;
        margin-bottom: 22px;

        .label-subtext {
          padding-left: 5px;
          color: #888888;
        }
        @include mobile() { display: none; }
      }

      &.field-submit {
        margin-top: 10px;
      }

      &.field-agree-all {
        background: #ffffff;
        border: solid 1px #e2e2e2;
        padding: 16px 20px;
        @include mobile() { padding: 10px 12.5px; }
      }
      &.field-agreements {
        background: #ffffff;
        border: solid 1px #e2e2e2;
        border-top: none;
        padding: 22px 20px;

        @include mobile() { padding: 15px 12.5px; }
        li {
          position: relative;
          line-height: 24px;
          margin-bottom: 15px;

          .btn-see {
            position: absolute;
            top: 0;
            right: 0;
            color: #4778d9;
            text-decoration: none;
            @include mobile() { font-size: 13px; }
          }
          &:last-child { margin-bottom: 0; }
        }
      }
      &.ag__field--error {
        input[type=text], input[type=email], input[type=password] {
          padding-bottom: 48px;
          @include mobile() { padding-bottom: 28px; }
        }
        .ag__error-message { display: block; }
      }
    }

    .ag__error-message {
      display: none;
      position: absolute;
      left: 20px;
      bottom: 18px;
      font-size: 14px;
      color: #e34840;
      @include mobile() { font-size: 12px; left: 13px; bottom: 6px; }
    }
  }

  .ag__email, .ag__password, .ag-form__password-confirmation {
    font-family: "Helvetica Neue", Helvetica, sans-serif;
    color: #4778d9;
    ime-mode: disabled;
  }

  .ag__email-annotation {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: #e0e0e0;
    font-size: 1px;
    color: transparent;
    background-size: 24px 24px;

    &:after {
      font-size: 16px;
      content: "?";
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      display: block;
      left: 0.5px;
      top: 1px;
      width: 100%;
      height: 100%;
      text-align: center;
    }
    @include mobile() {
      width: 20px; height: 20px;
      right: 12.5px;
      &:after {
        font-size: 14px;
        left: 0;
      }
    }

    .ag__email-annotation-body {
      display: none;
      position: absolute;
      top: 35px;
      left: -88px;
      width: 175px;
      border-radius: 2px;
      background-color: #616161;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      padding: 8px 10px;
      z-index: 1;

      // arrow
      &:after {
        display: block;
        content: "";
        position: absolute;
        top: -5px;
        left: 95px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #616161;
      }
    }
  }


  .ag__submit {
    width: 100%;
    box-sizing: border-box;
    height: 60px;
    background-color: #4778d9;
    border: none;
    box-shadow: inset 0 0 0 1px #3f6dca;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    transition: all .3s ease;

    @include mobile() { height: 45px; font-size: 15px; }
    &:disabled {
      color: rgba(255, 255, 255, .5)
    }
  }

  .ag__find-password {
    font-size: 15px;
    color: #888888;
    text-decoration: underline;
    font-weight: normal;

    @include mobile() { font-size: 13px; }
  }


  /* custom checkbox styling */
  input[type=checkbox] {
    position: relative;
    width: 28px;
    height: 28px;
    margin-right: 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0;

    @include mobile() { width: 20px; height: 20px; margin-right: 6px; }
    &:after {
      font-family: 'Material Icons';
      text-indent: 3px;
      content: '\e5ca';
      font-size: 20px;
      line-height: 24px;
      color: #dddddd;
      display: inline-block;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      border: solid 1px #dddddd;

      @include mobile() { font-size: 14px; line-height: 18px; text-indent: 2px; }
    }
    &:checked {
      background-color: #ffffff;
    }
    &:checked:after {
      color: #4778d9;
      border-color: #4778d9;
    }
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    input[type=checkbox] {
      width: inherit;
      height: inherit;
      vertical-align: middle;
    }
  }
  /* end of custom checkbox styling */

  .label-text {
    font-size: 15px;
    color: #333333;
    @include mobile() { font-size: 13px; }
  }


  /* sso login page customizations */
  &.ag-sso-login {
    .login-option-panel {
      margin-bottom: 52px;

      .description {
        font-size: 16px;
        color: #555459;
        margin-bottom: 20px;
      }

      &.login-option-panel--agit {
        margin-top: 52px;
      }
      &.login-option-panel--sso-only {
        margin-bottom: 30px;

        .description {
          width: 520px;
          position: relative;
          left: -10px;
          white-space: nowrap;
        }
      }
    }

    .login-button {
      display: block;
      position: relative;
      height: 60px;
      box-sizing: border-box;
      padding-top: 11px;
      background-color: #4778d9;
      border: solid 1px #3f6dc9;
      color: #fff;
      text-decoration: none;
      .icon {
        width: 60px;
        height: 60px;
        position: absolute;
        top: -1px;
        left: -1px;
        background: #329682;
        img {
          display: inline-block;
          margin-top: 17px;
        }
      }
      .label {
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        height: 22px;
        margin-top: 7px;
      }

      &.login-button--sso {
        background-color: #36a38d;
        border: solid 1px #329682;

        .label {
          margin-top: 0;
        }
        .sso-domain {
          font-size: 13px;
          font-weight: normal;
          line-height: 15px;
        }
      }
    }

    .login-option-split {
      position: relative;
      border-top: solid 1px #e8e8e8;
      margin: 12px 0 11px;
      span {
        position: absolute;
        top: -11px;
        left: 50%;
        transform: translate(-50%, 0);
        display: inline-block;
        width: 30px;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.6;
        color: #828185;
        padding: 0 10px;
        background: #f9f9f9;
      }
    }

    .admin-login a {
      font-size: 16px;
      color: #497bd8;
      text-decoration: underline;
    }
  }
}

html[lang=en] .ag-login {
  .ag__subtitle {
    font-size: 13px;
  }
}

html[lang=ja] .ag-login {
  .ag__subtitle {
    font-size: 11px;
  }
}


/* loading bar component -------------------------------------------------- */
.loading-bar {
  text-align: center;
  min-width: 100px;

  span {
    display: inline-block;
    margin: 0 6px 0 0;
    width: 20px;
    height: 20px;
    background-color: #5985e0;
  }

  span:last-child {
    margin-right: 0 !important;
  }
}

.loading-bar span:nth-child(1) {
  animation: loading 0.5s infinite alternate 0s;
}

.loading-bar span:nth-child(2) {
  animation: loading 0.5s infinite alternate 0.2s;
}

.loading-bar span:nth-child(3) {
  animation: loading 0.5s infinite alternate 0.4s;
}

.loading-bar span:nth-child(4) {
  animation: loading 0.5s infinite alternate 0.6s;
}

@keyframes loading {
  0% {
    opacity: .1;
  }
  100% {
    opacity: 1;
  }
}
/* END loading bar component ---------------------------------------------- */



/* ----------------------------------------------------------------------------
 * `/logout` Page
 */
.ag-logout {
  padding-top: 130px;

  .logo-wrap {
    margin-bottom: 20px;
  }
  .agit-rounded-logo {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    background-image: url("https://t1.kakaocdn.net/agit_resources/images/agit_logo_rounded.svg");
    background-size: cover;
  }

  .title {
    line-height: 36px;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    color: #4778d9;
  }
  .subtitle {
    height: 36px;
    font-size: 30px;
    font-weight: 300;
    text-align: center;
    color: #000000;
    margin-bottom: 20px;
  }
  .ag__submit {
    margin-bottom: 55px;
  }

  .error {
    color: #e34840;
  }
  .error-detail {
    text-align: left;
    padding: 10px;
    list-style-type: disc;
    color: #e34840;
  }
}
